import logo from './logo.svg';
import GodfatherImg from './godfather.jpg';
import GodfatherImg2 from './427476.jpg';
import GodfatherImg3 from './427477.jpg';
import GodfatherImg4 from './427478.jpg';
import './App.css';
import { useMediaQuery } from 'react-responsive';

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(max-width: 900px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 650px)' })

  return (
    <div className="App" style={{ background: '#000', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <img src={isTabletOrMobile ? GodfatherImg3 : isDesktopOrLaptop ? GodfatherImg2 : GodfatherImg4} alt="" style={{ height: '100%', width: '100%' }} />
    </div>
  );
}

export default App;
